.layout {
    display: grid;
    grid-template-columns: 1fr;
}

.heroVideo {
    display: none;
}

.heroImage {
    grid-area: 1/1 / 3/2;
    position: relative;
    min-height: 6rem;
}
.heroImage img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.panel {
    grid-area: 2/1 / 3/2;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.75);
}
.content {
    grid-area: 3/1 / 4/2;
    background-color: var(--primary);
    padding: var(--space-md);
}

.graphic {
    position: relative;
    background-color: var(--secondary);
    height: 15vw;
}
.graphic img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.brand {
    font-weight: 600;
    font-size: 3rem;
    line-height: .95;
    text-transform: uppercase;
    margin-bottom: .25rem;
}
.brand span {
    color: var(--primary);
}
.theme {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1rem;
}


@media(min-width:800px) {
    .layout {
        position: relative;
        z-index: 1;
        grid-template-columns: repeat(2, 50%);
    }

    .heroVideo {
        display: block;
        grid-area: 1/1 / 4/3;
        position: relative;
        overflow: hidden;
    }
    .heroImage {
        display: none;
    }

    .panel {
        grid-area: 1/1 / 4/2;
        z-index: 2;
    }
    
    .content {
        grid-area: 3/2 / 5/3;
        z-index: 2;
        margin-bottom: calc( var(--space-lg) * -1);
    }
    
    .graphic {
        height: 7vw;
    }

    
}

@media(min-width:1100px) {
    .brand {
        font-size: 4.8rem;
    }
    .theme {
        font-size: 2.8rem;
        margin-bottom: 2rem;
    }
    
}


