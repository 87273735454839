@media(max-width:799px) {
    .layout {
        display: flex;
        flex-direction: column-reverse;

    }
}


@media(min-width:800px){
    .layout {
        display: grid;
        grid-template-columns: 3.5fr 2fr;
        grid-template-rows: var(--space-lg) auto var(--space-lg);
        /* padding-top: var(--space-lg) !important; */
    }

    .videoContainer {
        position: relative;
        z-index: 1;
        grid-area: 1/1 / 3/2;
    }

    .content {
        z-index: 1;
        grid-area: 2/2 / 4/3;
    }
    
    .bgLower {
        grid-area: 3/1 / 4/3;
    }
}