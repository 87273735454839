.layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--space-sm);
}
.layout:not(:last-child) {
    margin-bottom: var(--space-sm);
}

@media (min-width:600px) {
    .layout {
        grid-template-columns: repeat(4, 1fr );
    }
    
    .layout.lg {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: var(--space-md);
    }

}