.layout {
    display: grid;
    grid-template-columns: 100%;
    gap: var(--space-lg);
}

.arrows {
    position: absolute;
    bottom: -1rem;
    left: 1.5rem;
    width: 3rem;
}

.sliderButton {
    background-color: var(--primary);
    width: 3rem;
    height: 3rem;
    opacity: 1;
}
.sliderButton svg {
    width: 1rem;
    height: 1rem;
}
.prev {
    left: -1.8rem;
}
.next {
    right: -1.8rem;
}

.actions {
    margin-top: var(--space-md);
}


@media(min-width:800px){
    .arrows {
        bottom: -3rem;
    }
    .actions {
        margin-top: var(--space-md);
    }

}


@media(min-width:1200px) {
    .arrows {
        position: absolute;
        bottom: -3rem;
        left: 1.5rem;
        width: 4.5rem;
    }
    
    .sliderButton {
        width: 3.6rem;
        height: 3.6rem;
    }
    .sliderButton svg {
        width: 1.4rem;
        height: 1.4rem;
    }

    .actions {
        margin-top: var(--space-sm);
    }
}





