.layout {
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    color: white;
}

.topGraphic {
    grid-area: 1/1 / 2/2;
    position: relative;
    z-index: 2;
    width: 100%;
    aspect-ratio: 1680/450;
}
.underlay {
    grid-area: 2/1 / 3/2;
    z-index: 1;
    background-color: var(--sand);
}


.content {
    grid-area: 2/1 / 3/2;
    z-index: 2;
}




.graphic {
    position: absolute;
    z-index: 4;
    top: 8vw;
    right: -4vw;
}




.headingGrid {
    display: flex;
    gap: var(--space-sm);
    align-items: center;
    flex-wrap: wrap;
}