.container {
    position: relative;
    background-color: white;
    text-align: center;
    padding-bottom: 1rem;
}

.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
}
.slider {
    display: inline-block;
    text-align: center;
    max-width: 60vw;
}
.slider.lg {
    max-width: 75vw;
}

@media(min-width:600px) {
    .heading {
        aspect-ratio: 4/1;
        padding: .25rem 1rem;
    }
    .container.lg .heading {
        aspect-ratio: auto;
        padding: 1rem;
    }
    
    .slider {
        max-width: 17vw;
    }
    .container.lg .slider {
        max-width: 35.6vw;
    }
}




/* logo layout --------------------------------------- */

.logo {
    display: inline-block;
    position: relative;
    mix-blend-mode: multiply;
    width: 100%;
    aspect-ratio: 4/2;
    padding: 2rem;
}
.logo img {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

@media(min-width:800px) {
    .logo.lg {
        width: 24vw;
    }
}