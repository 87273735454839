.layout {
    padding-top: var(--space-md);
}


.column {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    gap: var(--space-sm);
}
.column:nth-child(2) {
    margin-top: var(--space-sm);
}



@media(min-width:900px){
    .layout {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        gap: var(--space-sm);
        padding-top: calc(var(--space-lg) * 2 )!important;
    }
    .column:nth-child(2) {
        margin-top: 0;
    }
}




/* CARD */

.card {
    display: grid;
    grid-template-columns: 1fr;

    background-color: white;
}

.cardGraphic {
    position: relative;
    background-color: var(--primary);
}

.cardContent {
    padding: var(--space-sm);
}

.cardHero {
    position: relative;
    background-color: rgba(0, 0, 0, 0.25);
}
.cardHero img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

@media(max-width:599px){
    .cardHero {
        min-height: 14rem;
        grid-row-start: 1;
    }
}

@media(min-width:600px){
    .card {
        grid-template-columns: 3rem auto 20vw;
    }
}